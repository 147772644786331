




























import { Component, Vue } from "vue-property-decorator";
import CourseCard from "@/components/CourseCard.vue";
import firebase from "@/plugins/firebase";

@Component({
  components: {
    CourseCard
  }
})
export default class FeaturedCourses extends Vue {
  courses: {
    id: string;
    code: string;
    urlCode: string;
    name: string;
    desc: string;
  }[] = [];

  getCourses(): void {
    this.courses = [];

    firebase
      .firestore()
      .collection("courses")
      .where("disabled", "==", false)
      .orderBy("name", "asc")
      .limit(3)
      .get()
      .then(query => {
        query.forEach(doc => {
          const newItem = {
            id: doc.id,
            code: doc.data().code,
            urlCode: doc.data().urlCode,
            name: doc.data().name,
            desc: doc.data().desc.short
          };

          this.courses.push(newItem);
        });
      });
  }

  mounted(): void {
    this.getCourses();
  }
}
