










































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CourseCard extends Vue {
  @Prop() img!: string;
  @Prop() code!: string;
  @Prop() urlCode!: string;
  @Prop() name!: string;
  @Prop() desc!: string;
  @Prop() studentCount!: number;
  @Prop() background!: string;
}
