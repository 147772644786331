
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Features extends Vue {
  items = [
    {
      icon: require("@/assets/icon-elearning.svg"),
      title: "İnteraktif e-Dersler",
      text:
        "Metin, ses, video ve animasyonlarla zenginleştirilmiş interaktif e-derslerle çevrimiçi öğrenmenin keyfini yaşayın."
    },
    {
      icon: require("@/assets/icon-award.svg"),
      title: "Katılım Sertifikası",
      text:
        "Başarıyla tamamladığınız her bir eğitim için adınıza özel ve geçerliliği doğrulanabilir katılım sertifikası düzenlenir."
    },
    {
      icon: require("@/assets/icon-videoconference.svg"),
      title: "Dijital Vatandaş Kimliği",
      text:
        "Tüm eğitimleri tamamlayanlara Internet Society ve Medya Okuryazarlığı Derneği onaylı dijital vatandaşlık kimliği verilir."
    }
  ];

  items_old = [
    {
      icon: require("@/assets/icon-elearning.svg"),
      title: "e-Öğrenme",
      text:
        "Güncel uzaktan eğitim teknikleriyle hazırladığımız öztempolu online eğitimler ile bağımsız öğrenmeyi keşfedeceksiniz."
    },
    {
      icon: require("@/assets/icon-award.svg"),
      title: "Atölye Çalışmaları",
      text:
        "Sosyal öğrenme teknikleriyle harmanladığımız çevrimiçi atölye çalışmalarında, kendinizi geliştirebilirsiniz."
    },
    {
      icon: require("@/assets/icon-videoconference.svg"),
      title: "Seminerler",
      text:
        "Düzenli aralıklarla gerçekleştirilen online semilerlerde alan uzmanlarından güncel bilgiler alabilirsiniz."
    }
  ];
}
